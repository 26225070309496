.title
  width: 100%
  font-size: 50px
  font-weight: 700
  text-align: center

.form
  width: 40%
  border-radius: 10px
  background-color: #ffffff
  box-shadow: 0 12px 20px rgba(68,68,68,0.2)
  padding: 30px 20px

.input
  width: 40%
  background-color: #ffffff

  input
    border: 0
    background-color: #f6f6f6
    padding-left: 60px

    &:focus
      box-shadow: none

  select
    border: 0
    background-color: #f6f6f6
    padding-left: 60px

    &:focus
      box-shadow: none

.return
  font-size: 17px